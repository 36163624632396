<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Clients & Reviews</h2>

  <div class="spacer-60"></div>

  <!-- testimonials wrapper -->
  <div class="testimonials-wrapper">
    <ngb-carousel *ngIf="images" [interval]="5000" [showNavigationArrows]="true">
      <ng-template ngbSlide>
        <div class="testimonial-item text-center mx-auto">
          <div class="thumb mb-3 mx-auto">
            <img src="assets/images/gallery/testimoni/avatar.png" alt="nopic" />
          </div>
          <h4 class="mt-3 mb-0">Head Finance</h4>
          <span class="subtitle">Finance at PT.Solusi Kebutuhan Teknologi (Prieds)</span>
          <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
            <p class="mb-0">
              Very nice! The type of person who works hard without seeing the time alias no-life. if it's exciting in the job you want to pursue.
            </p>
          </div>
        </div>
      </ng-template>
      <!-- <ng-template ngbSlide>
        <div class="testimonial-item text-center mx-auto">
          <div class="thumb mb-3 mx-auto">
            <img src="assets/images/avatar-3.svg" alt="customer-name" />
          </div>
          <h4 class="mt-3 mb-0">John Doe</h4>
          <span class="subtitle">Product designer at Dribbble</span>
          <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
            <p class="mb-0">
              I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍
            </p>
          </div>
        </div>
      </ng-template> -->
    </ngb-carousel>
  </div>

  <div class="row" style="margin-top: 60px; display:flex; justify-content: center;">
    <div class="col-md-3 col-6">
      <!-- client item -->
      <div class="client-item">
        <div class="inner">
          <img src="assets/images/gallery/client/prieds.png" alt="prieds" />
        </div>
      </div>
    </div>

    <div class="col-md-3 col-6">
      <div class="client-item">
        <div class="inner">
          <img src="assets/images/gallery/client/csrg.png" alt="ptcsrg" />
        </div>
      </div>
    </div>

    <div class="col-md-3 col-6">
      <div class="client-item">
        <div class="inner">
          <img src="assets/images/gallery/experience/binus.png" alt="binus" />
        </div>
      </div>
    </div>
  </div>
</div>
