<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">About Me</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-3">
      <div class="text-center text-md-left profile">
        <!-- avatar image -->
        <img [src]="appconfigservice.image_profile_url" alt="David" class="img-profile" />
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-9 triangle-left-md triangle-top-sm">
      <div class="rounded bg-white shadow-dark padding-30">
        <div class="row">
          <div class="col-md-12">
            <!-- about text -->
            <p style="text-align:justify">
              Hello, I'm David, a professional based in Indonesia. I specialize in the dynamic field of full-stack development, with expertise in Angular, Node.js, JavaScript, MongoDB, Python, and advertising. My passion lies in creating clean and efficient solutions that leverage cutting-edge technologies.
            </p>
            <p style="text-align:justify">
              Beyond the coding realm, I have a flair for simple yet impactful design. Crafting visually appealing interfaces is not just a job for me; it's a creative outlet that adds a touch of artistry to my technical skills.
            </p>
            <p style="text-align:justify">
              When I'm not immersed in the world of coding and design, you'll often find me indulging in my other passion – gaming. Whether it's exploring virtual worlds or strategizing in competitive games, I appreciate the immersive experiences that gaming has to offer.
            </p>
            <p style="text-align:justify">
              Let's collaborate and bring your ideas to life with a blend of technical expertise, creative design, and a touch of gaming enthusiasm. I'm excited about the possibilities that each new project brings, and I look forward to creating something exceptional together.
            </p>
            <div class="mt-3">
              <!-- <a href="javascript:" class="btn btn-default">Download CV</a> -->
            </div>
            <div class="spacer-30 d-md-none d-lg-none"></div>
          </div>
          <!-- <div class="col-md-6">
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Frontend Development</h4>
                <span class="float-right">85%</span>
              </div>
              <ngb-progressbar type="warning" [value]="85"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>
            
            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Backend Development</h4>
                <span class="float-right">70%</span>
              </div>
              <ngb-progressbar type="primary" [value]="70"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Javascript</h4>
                <span class="float-right">80%</span>
              </div>
              <ngb-progressbar type="danger" [value]="80"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

            <div class="skill-item">
              <div class="skill-info clearfix">
                <h4 class="float-left mb-3 mt-0">Python</h4>
                <span class="float-right">50%</span>
              </div>
              <ngb-progressbar type="success" [value]="50"></ngb-progressbar>
              <div class="spacer-20"></div>
            </div>

          </div> -->
        </div>
      </div>
    </div>
  </div>
  <!-- row end -->

  <div class="spacer-70"></div>

  <div class="row display">
    <!-- <div class="col-md-3 col-sm-6">
      <div class="fact-item">
        <span class="icon icon-fire"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">0</em></h3>
          <p class="mb-0">Projects completed</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div> -->

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-cup"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">{{count}}</em></h3>
          <p class="mb-0">Cup of coffee</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item">
        <span class="icon icon-people"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">2</em></h3>
          <p class="mb-0">Project Personal</p>
        </div>
      </div>
      <div class="spacer-30 d-md-none d-lg-none"></div>
    </div>

    <div class="col-md-3 col-sm-6">
      <!-- fact item -->
      <div class="fact-item cursor" (click)="exampleModalCenter.show()">
        <span class="icon icon-badge"></span>
        <div class="details">
          <h3 class="mb-0 mt-0 number"><em class="count">1</em></h3>
          <p class="mb-0">Nominees winner</p>
        </div>
      </div>
    </div>
  </div>
</div>


<app-ui-modal #exampleModalCenter dialogClass="modal-dialog-centered work-modal" [hideHeader]="true" [hideFooter]="true">
  <div class="app-modal-body">
    <div id="small-dialog" class="white-popup zoom-anim-dialog mfp-hide">
      <ngb-carousel *ngIf="images" [interval]="5000" [showNavigationArrows]="true">
        <ng-template ngbSlide>
          <div class="testimonial-item text-center mx-auto">
            <!-- <div class="thumb mb-3 mx-auto">
              <img src="assets/images/gallery/banner/nominate1.png" alt="customer-name" class="img-banner"/>
            </div> -->
            <img src="assets/images/gallery/banner/nominate1.png" alt="customer-name" class="img-banner"/>
            <h4 class="mt-3 mb-0">STMIK TIME - Event Organizer</h4>
            <span class="subtitle">@Nov 2014 - Fresh Markizza</span>
            <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
              <p class="mb-0">
                Tweets : "100 thousand can run a blog business and a domain for advertising on your own blog if there are lots of visitors"
              </p>
            </div>
          </div>
        </ng-template>
        <!-- <ng-template ngbSlide>
          <div class="testimonial-item text-center mx-auto">
            <div class="thumb mb-3 mx-auto">
              <img src="assets/images/avatar-3.svg" alt="customer-name" />
            </div>
            <h4 class="mt-3 mb-0">John Doe</h4>
            <span class="subtitle">Product designer at Dribbble</span>
            <div class="bg-white padding-30 shadow-dark rounded triangle-top position-relative mt-4">
              <p class="mb-0">
                I enjoy working with the theme and learn so much. You guys make the process fun and interesting. Good luck! 👍
              </p>
            </div>
          </div>
        </ng-template> -->
      </ngb-carousel>

      <!-- <img src="assets/images/single-work.svg" alt="Title" />
      <h2>Guest App Walkthrough Screens</h2>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam hendrerit nibh in massa semper rutrum. In rhoncus eleifend mi id
        tempus.
      </p>
      <p>Donec consectetur, libero at pretium euismod, nisl felis lobortis urna, id tristique nisl lectus eget ligula.</p>
      <a href="javascript:" class="btn btn-default">View on Dribbble</a> -->
    </div>
  </div>
</app-ui-modal>