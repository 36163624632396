<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Experience</h2>

  <div class="spacer-60"></div>

  <div class="row">
    <div class="col-md-6">
      <!-- timeline wrapper -->
      <div class="timeline edu rounded bg-white shadow-dark padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2017 - Present</span>
            <h3 class="title">Academic Degree</h3>
            <p>Learn and deepen from work experience</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2017 - 2013</span>
            <!-- graduated with bachelor degree in computer engineering -->
            <!-- <img src="assets/images/education/stmik-time.jpg" alt="ptcsrg" width="50%"/> -->
            <h3 class="title">Bachelor’s Degree Computer Engineering</h3>
            <p>College of Informatics & Computer Management TIME (Technology Information Management and Education) in Medan, Indonesia</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2013 - 2009</span>
            <h3 class="title">High School Graduate</h3>
            <p>Wr. Supratman 1 School in Medan, Indonesia</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">2011 & 2009</span>
            <h3 class="title">VISITEK & TRICOM</h3>
            <p>Take additional skills in Computer or Hardware Technicians and Graphic Design Tools</p>
          </div>
        </div>

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>

    <div class="col-md-6">
      <!-- responsive spacer -->
      <div class="spacer-30 d-md-none d-lg-none"></div>

      <!-- timeline wrapper -->
      <div class="timeline exp bg-white shadow-dark rounded padding-30 overflow-hidden">
        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2023 - Present ({{appconfigservice.yearNow}})</span>
            <h3 class="title">Freelance</h3>
            <p>Freelancers offer their services or expertise to various clients or companies for specific projects.
            </p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp">
          <div class="content">
            <span class="time">2019 - 2023</span>
            <img src="assets/images/gallery/experience/prieds.jpeg" alt="prieds" width="50%"/>
            <h3 class="title">PT. Solusi Kebutuhan Teknologi (PRIEDS)</h3>
            <p>as a product/project team for the frontend developer</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">Feb 2019 - Des 2016</span>
            <img src="assets/images/gallery/experience/pt_csrg.jpeg" alt="ptcsrg" />
            <h3 class="title">PT. Cahaya Samudera Rimbah Gemilang</h3>
            <p>as site manager, computer technician, purchasing and invoice management</p>
          </div>
        </div>

        <!-- timeline item -->
        <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">Des 2016 - 2015</span>
            <img src="assets/images/gallery/experience/binus.png" alt="binus" width="60%" />
            <h3 class="title">Binus Center Gajah Mada Medan</h3>
            <p>as a language teacher for the Visual Basic C++ program and a teacher's assistant</p>
          </div>
        </div>

        <!-- timeline item -->
        <!-- <div class="timeline-container wow fadeInUp" data-wow-delay="0.2s">
          <div class="content">
            <span class="time">2015 - 2014</span>
            <h3 class="title">Internasional Keramik</h3>
            <p>as Invoice and Purchase Management</p>
          </div>
        </div> -->

        <!-- timeline item -->
        <!-- <div class="timeline-container wow fadeInUp" data-wow-delay="0.4s">
          <div class="content">
            <span class="time">Aug 2013 - Apr 2013</span>
            <h3 class="title">PT. Trijaya Pratama Futures</h3>
            <p>as a Forex and trading consultant</p>
          </div>
        </div> -->

        <!-- main line -->
        <span class="line"></span>
      </div>
    </div>
  </div>
</div>
