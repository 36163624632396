<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Used Tech</h2>

  <div class="spacer-30"></div>

  <div class="row display">
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/mongodb.png" alt="mongodb logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/expressjs.png" alt="expressjs logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/angular.png" alt="angular logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/nodejs.png" alt="nodejs logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/bootstrap.png" alt="bootstrap logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/typescript.png" alt="typescript logo" width="80">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/c++.png" alt="c++ logo" width="80">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/python.png" alt="python logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/github.png" alt="github logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/git.png" alt="git logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/linux.png" alt="linux logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/nginx.png" alt="nginx logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/apache.png" alt="apache logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/npm.png" alt="npm logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/postgresql.png" alt="postgresql logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/cloudflare.png" alt="cloudflare logo" width="200">
    </div>
  </div>
</div>

<div class="spacer-96"></div>

<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Hosting Server</h2>

  <div class="spacer-30"></div>

  <div class="row display">
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/digitalocean.png" alt="digitalocean logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/githubpages.png" alt="githubpages logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/vercel.png" alt="vercel logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/googledomain.png" alt="googledomain logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/firebase.png" alt="firebase logo" width="200">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/bloggerlogo.png" alt="blogger logo" width="200">
    </div>
  </div>
</div>

<div class="spacer-96"></div>

<div class="container">
  <!-- section title -->
  <h2 class="section-title wow fadeInUp">Editor</h2>

  <div class="spacer-30"></div>

  <div class="row display">
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/vscode.png" alt="vscode logo" width="80">
    </div>
    <div class="col-md-3 col-sm-6 pt-4 text-center align-content-center">
      <img src="../../../../assets/logo/androidstudio.png" alt="androidstudio logo" width="200">
    </div>
  </div>
</div>