import { AppConfigService } from 'src/app/app-config.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  constructor(
    public appconfigservice : AppConfigService
  ) {}

  count = 0;
  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  countCupofCoffee() {
    const startDate = new Date('2019-02-10T00:00:00').valueOf();
    const today = new Date().valueOf();
    const diff = Math.floor((today-startDate)/(1000*60*60*24));
    this.count = 0 + diff * 1;
  }

  ngOnInit(): void {
    this.countCupofCoffee();
  }
}
