import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-works',
  templateUrl: './works.component.html',
  styleUrls: ['./works.component.scss']
})
export class WorksComponent implements OnInit {
  public workFilter: string;
  public singleGallery1: any;
  public albums: any = [];

  videoObject: Array<object> = [
    {
      video: 'https://www.youtube.com/watch?v=qf9z4ulfmYw',
      posterImage: 'assets/images/works/3.svg'
    }
  ];

  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);
  sliderList = [
    {
      image : 'assets/images/gallery/pictures/project-uas.png',
      title : 'STMIK TIME - Project',
      subtitle : '-',
      content : 'Project Purchasing using VB.Net and Microsoft Access as DB'
    },
    {
      image : 'assets/images/gallery/pictures/project-skripsi.jpg',
      title : 'STMIK TIME - Undergraduate thesis',
      subtitle : '@2017',
      content : 'Arduino earthquake detection uses a gyro component with an earthquake scale above 4 on the Richter Scale'
    },
  ];

  constructor() {
    this.workFilter = 'all';
    this.singleGallery1 = [
      {
        src: 'assets/images/gallery/pictures/project-uas.png',
        caption: 'STMIK TIME - Project Purchasing using VB.Net and Microsoft Access as DB',
        // thumb: 'assets/images/works/1.svg'
        thumb: 'assets/images/gallery/thumb/college-project.png',
      },
      {
        src: 'assets/images/gallery/pictures/project-skripsi.jpg',
        caption: 'STMIK TIME - Undergraduate thesis Arduino earthquake detection uses a gyro component with an earthquake scale above 4 on the Richter Scale',
        thumb: 'assets/images/gallery/pictures/project-skripsi.jpg',
      }
    ];

    // for (let i = 5; i >= 1; i--) {
    //   const album = {
    //     // src: 'assets/images/works/' + i + '.svg',
    //     src: 'assets/images/gallery/pictures/project-uas.png',
    //     caption: 'Image ' + i + ' caption here',
    //     // thumb: 'assets/images/works/' + i + '.svg'
    //     thumb: 'assets/images/gallery/pictures/project-uas.png',
    //   };

    //   this.albums.push(album);
    // }
  }

  ngOnInit(): void {}

  onChange(e) {
    this.workFilter = e.target.value;
  }
}
