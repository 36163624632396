import { Component, OnInit, Input } from '@angular/core';
import { AppConfigService } from 'src/app/app-config.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent implements OnInit {
  constructor(
    public appconfigservice : AppConfigService,
  ) {}

  ngOnInit(): void {}
}
