import { Component, OnInit } from '@angular/core';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  constructor() {}

  public sendEmail(e: Event) {
    e.preventDefault();
    emailjs.sendForm(
      'service_1ifwm8b',
      'template_9o003ll',
      e.target as HTMLFormElement,
      'gcQLKIU2dVJzPSvbd'
    ).then((result: EmailJSResponseStatus) => {
        if(result['status'] === 200) {
          (document.getElementById("name") as HTMLInputElement ).value = '';
          (document.getElementById("email") as HTMLInputElement ).value = '';
          (document.getElementById("subject") as HTMLInputElement ).value = '';
          (document.getElementById("message") as HTMLInputElement ).value = '';

          alert("Success Email to User!");
        }
      },(error) => {
        alert("Failed Email to User!");
      }
    );
  }
  
  ngOnInit(): void {}
}
